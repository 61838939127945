import {
  FILTER_POSTAL_CODE,
  FILTER_DISTANCE_IN_MILES,
  FILTER_AVAILABILITY,
  FILTER_DELIVERY_METHODS,
  FILTER_SIZE,
  FILTER_COLOR,
  FILTER_COAT,
  FILTER_TAIL,
  FILTER_GENERATION,
  FILTER_GENDER,
  FILTER_HEALTH_TIER_LEVELS,
  FILTER_PRICE,
  FILTER_AGE
} from 'dpl/constants/query_params';

export const RESULTS_TYPES = {
  PUPPIES: 'puppies',
  BREEDERS: 'breeders'
};

export const SORT_DEFAULT = 'default';
export const SORT_NEAR_YOU = 'location';
export const SORT_PRICE_LOW_TO_HIGH = 'price_asc';
export const SORT_PRICE_HIGH_TO_LOW = 'price_desc';
export const SORT_AVAILABILITY = 'availability';
export const SORT_RECENTLY_ADDED = 'recently_added';

export const NON_LOCATION_SORT_OPTIONS = [
  SORT_PRICE_LOW_TO_HIGH,
  SORT_PRICE_HIGH_TO_LOW,
  SORT_AVAILABILITY,
  SORT_RECENTLY_ADDED
];

export const SORT_OPTIONS = [
  {
    value: SORT_DEFAULT,
    title: 'Default'
  },
  {
    value: SORT_NEAR_YOU,
    title: 'Near you'
  },
  {
    value: SORT_PRICE_LOW_TO_HIGH,
    title: 'Price: Low to high'
  },
  {
    value: SORT_PRICE_HIGH_TO_LOW,
    title: 'Price: High to low'
  },
  {
    value: SORT_AVAILABILITY,
    title: 'Soonest available'
  },
  {
    value: SORT_RECENTLY_ADDED,
    title: 'Recently added'
  }
];

export const PUPPY_GENDERS = {
  BOY: 'boy',
  GIRL: 'girl'
};

export const FILTER_PARAMS = [
  FILTER_POSTAL_CODE,
  FILTER_DISTANCE_IN_MILES,
  FILTER_AVAILABILITY,
  FILTER_DELIVERY_METHODS,
  FILTER_SIZE,
  FILTER_COLOR,
  FILTER_COAT,
  FILTER_TAIL,
  FILTER_GENERATION,
  FILTER_GENDER,
  FILTER_PRICE,
  FILTER_HEALTH_TIER_LEVELS,
  FILTER_AGE
];

export const PUPPY_CARD_CTA_TYPES = {
  NONE: 'none',
  LINK: 'link',
  BUTTON: 'button'
};
